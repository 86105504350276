import AuthRepository from '@/api/repositories/AuthRepository';
import {AuthState, RootState} from '@/interfaces/StoreStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import AccountRepository from "@/api/repositories/AccountRepository";
import Account from '@/models/Account';
import {AccountType} from "@/enum/AccountType.enum";
import RestauranteurRepository from "@/api/repositories/RestauranteurRepository";
import Restauranteur from "@/models/Restaurateur";
import PlatformOperatorRepository from "@/api/repositories/PlatformOperatorRepository";
import PlatformOperator from "@/models/PlatformOperator";
import SSORepository from "@/api/repositories/SSORepository";

export const AUTH_STORE_NAME = 'auth';

export const enum AuthStoreActions {
  LOGIN = 'LOGIN',
  GOOGLE_LOGIN = 'GOOGLE_LOGIN',
  APPLE_LOGIN = 'APPLE_LOGIN',
  LOGIN_WITH_TOKEN = 'LOGIN_WITH_TOKEN',
  INITIATE_PASSWORD_RESET = 'INITIATE_PASSWORD_RESET',
  RESET_PASSWORD = 'RESET_PASSWORD',
  REGISTER_RESTAURATEUR = 'REGISTER_RESTAURATEUR',
}

export const enum AuthStoreMutations {
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SET_TOKEN = 'SET_TOKEN',
  SET_USER = 'SET_USER',
  SET_ACCOUNT = 'SET_ACCOUNT',
  CLEAR_STORE = 'CLEAR_STORE',
}

export const enum AuthStoreGetters {
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  TOKEN = 'TOKEN',
  CURRENT_USER = 'CURRENT_USER',
  CURRENT_ACCOUNT = 'CURRENT_ACCOUNT',
}

function initialAuthState(): AuthState {
  return {
    refreshToken: undefined,
    token: undefined,
    currentUser: undefined,
    currentAccount: undefined,
  };
}

const store: AuthState = initialAuthState();

const actions: ActionTree<AuthState, RootState> = {
  [AuthStoreActions.LOGIN]: async ({ commit }, payload: { username: string, password: string, code?: string })
      : Promise< Restauranteur | PlatformOperator | null> => {
    // fetches the tokens from the api
    const tokens = await AuthRepository.getAccessToken(payload);

    // saves tokens to store
    commit(AuthStoreMutations.SET_TOKEN, tokens.data.access_token);
    commit(AuthStoreMutations.SET_REFRESH_TOKEN, tokens.data.refresh_token);

    // gets the account based on the token and saves it to the store
    const accountResponse = await AccountRepository.getMyAccount();
    const account = Account.parseFromObject(accountResponse.data);
    commit(AuthStoreMutations.SET_ACCOUNT, account);

    // gets the user / employee / restauranteur object by the token and the role in the account
    let user: Restauranteur | PlatformOperator | null = null;
    switch(account.role) {
      case AccountType.GN:
        const restauranteurResponse = await RestauranteurRepository.getRestauranteurByToken();
        user = Restauranteur.parseFromObject(restauranteurResponse.data);
        break;
      case AccountType.PB:
        const pbResponse = await PlatformOperatorRepository.getPBByToken();
        user = PlatformOperator.parseFromObject(pbResponse.data);
        break;
    }

    // checks if the user has the correct role and either resets the store or sets the user to the store
    if(!user) {
      commit(AuthStoreMutations.CLEAR_STORE);
      return null;
    }

    commit(AuthStoreMutations.SET_USER, user);
    return user;
  },
  [AuthStoreActions.LOGIN_WITH_TOKEN]: async ({ commit }, payload: { token: string, refreshToken: string })
      : Promise< Restauranteur | PlatformOperator | null> => {
    // saves tokens to store
    commit(AuthStoreMutations.SET_TOKEN, payload.token);
    commit(AuthStoreMutations.SET_REFRESH_TOKEN, payload.refreshToken);

    // gets the account based on the token and saves it to the store
    const accountResponse = await AccountRepository.getMyAccount();
    const account = Account.parseFromObject(accountResponse.data);
    commit(AuthStoreMutations.SET_ACCOUNT, account);

    // gets the user / employee / restauranteur object by the token and the role in the account
    let user: Restauranteur | PlatformOperator | null = null;
    switch(account.role) {
      case AccountType.GN:
        const restauranteurResponse = await RestauranteurRepository.getRestauranteurByToken();
        user = Restauranteur.parseFromObject(restauranteurResponse.data);
        break;
      case AccountType.PB:
        const pbResponse = await PlatformOperatorRepository.getPBByToken();
        user = PlatformOperator.parseFromObject(pbResponse.data);
        break;
      case AccountType.EN:
        case AccountType.GNM:
          return null;
    }

    // checks if the user has the correct role and either resets the store or sets the user to the store
    if(!user) {
      commit(AuthStoreMutations.CLEAR_STORE);
      return null;
    }

    commit(AuthStoreMutations.SET_USER, user);
    return user;
  },
  [AuthStoreActions.GOOGLE_LOGIN]: async ({ commit }, payload: { idToken: string})
      : Promise< Restauranteur | PlatformOperator | null> => {
    // fetches the tokens from the api
    const tokens = await SSORepository.loginWithGoogle(payload.idToken);

    commit(AuthStoreMutations.SET_TOKEN, tokens.data.access_token);
    commit(AuthStoreMutations.SET_REFRESH_TOKEN, tokens.data.refresh_token);

    // gets the account based on the token and saves it to the store
    const accountResponse = await AccountRepository.getMyAccount();
    const account = Account.parseFromObject(accountResponse.data);

    commit(AuthStoreMutations.SET_ACCOUNT, account);

    // gets the user / employee / restauranteur object by the token and the role in the account
    let user: Restauranteur | PlatformOperator | null = null;
    switch(account.role) {
      case AccountType.GN:
        const restauranteurResponse = await RestauranteurRepository.getRestauranteurByToken();
        user = Restauranteur.parseFromObject(restauranteurResponse.data);
        break;
      case AccountType.PB:
        const pbResponse = await PlatformOperatorRepository.getPBByToken();
        user = PlatformOperator.parseFromObject(pbResponse.data);
        break;
    }

    // checks if the user has the correct role and either resets the store or sets the user to the store
    if(!user) {
      commit(AuthStoreMutations.CLEAR_STORE);
      return null;
    }

    commit(AuthStoreMutations.SET_USER, user);
    return user;
  },
  [AuthStoreActions.APPLE_LOGIN]: async ({ commit }, payload: { idToken: string})
      : Promise< Restauranteur | PlatformOperator | null> => {
    // fetches the tokens from the api
    const tokens = await SSORepository.loginWithApple(payload.idToken);

    // saves tokens to store
    commit(AuthStoreMutations.SET_TOKEN, tokens.data.access_token);
    commit(AuthStoreMutations.SET_REFRESH_TOKEN, tokens.data.refresh_token);

    // gets the account based on the token and saves it to the store
    const accountResponse = await AccountRepository.getMyAccount();
    const account = Account.parseFromObject(accountResponse.data);
    commit(AuthStoreMutations.SET_ACCOUNT, account);

    // gets the user / employee / restauranteur object by the token and the role in the account
    let user: Restauranteur | PlatformOperator | null = null;
    switch(account.role) {
      case AccountType.GN:
        const restauranteurResponse = await RestauranteurRepository.getRestauranteurByToken();
        user = Restauranteur.parseFromObject(restauranteurResponse.data);
        break;
      case AccountType.PB:
        const pbResponse = await PlatformOperatorRepository.getPBByToken();
        user = PlatformOperator.parseFromObject(pbResponse.data);
        break;
    }

    // checks if the user has the correct role and either resets the store or sets the user to the store
    if(!user) {
      commit(AuthStoreMutations.CLEAR_STORE);
      return null;
    }

    commit(AuthStoreMutations.SET_USER, user);
    return user;
  },
  [AuthStoreActions.INITIATE_PASSWORD_RESET]: async ({ commit }, email: string): Promise<any> => {
    const response = await AuthRepository.initiatePasswordReset(email);
    return response.data;
  },
  [AuthStoreActions.RESET_PASSWORD]: async ({ commit }, payload: { token: string, password: string }): Promise<any> => {
    const response = await AuthRepository.resetPassword(payload.token, payload.password);
    return response.data;
  },
  [AuthStoreActions.REGISTER_RESTAURATEUR]: async ({ commit }, payload: { email: string, password: string, companyName: string }): Promise<any> => {
    const response = await AuthRepository.registerRestaurateur(payload.email, payload.password, payload.companyName);
    return response.data;
  }
};

const mutations: MutationTree<AuthState> = {
  [AuthStoreMutations.SET_TOKEN]: (state: AuthState, value: string | undefined) => {
    state.token = value;
  },
  [AuthStoreMutations.SET_REFRESH_TOKEN]: (state: AuthState, value: string | undefined) => {
    state.refreshToken = value;
  },
  [AuthStoreMutations.SET_USER]: (state: AuthState, value: Restauranteur) => {
    state.currentUser = value;
  },
  [AuthStoreMutations.SET_ACCOUNT]: (state: AuthState, value: Account | undefined) => {
    state.currentAccount = value;
  },
  [AuthStoreMutations.CLEAR_STORE]: (state: AuthState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialAuthState());
  }
};

const getters: GetterTree<AuthState, RootState> = {
  [AuthStoreGetters.TOKEN]: (state: AuthState) => state.token,
  [AuthStoreGetters.REFRESH_TOKEN]: (state: AuthState) => state.refreshToken,
  [AuthStoreGetters.CURRENT_USER]: (state: AuthState) => {
    return state.currentUser;
  },
  [AuthStoreGetters.CURRENT_ACCOUNT]: (state: AuthState) => {
    if (state.currentAccount) {
      // needs to be parsed because VuexPersistence does not keep type information
      return Account.parseFromObject(state.currentAccount);
    }
    return undefined;
  },
};

const authStore: Module<AuthState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default authStore;
