/**
 * gn views
 */
export const ROUTE_LOGIN = 'login';
export const ROUTE_CONNECT_PAYPAL = 'connect-paypal';
export const ROUTE_HOME = 'home';
export const ROUTE_STORE = 'store';
export const ROUTE_CONTAINERS = 'containers';
export const ROUTE_CONTAINERS_OVERVIEW = `${ROUTE_CONTAINERS}/overview`;
export const ROUTE_CONTAINERS_SUMMARY = `${ROUTE_CONTAINERS}/summary`;
export const ROUTE_PENALTY_PAYMENTS = 'penalty-payments';
export const ROUTE_HISTORY = 'history';
export const ROUTE_MICRO_SERVICES = 'micro-services';
export const ROUTE_MICRO_SERVICES_OVERVIEW = `${ROUTE_MICRO_SERVICES}/overview`;
export const ROUTE_MICRO_SERVICE_SUMMARY = `${ROUTE_MICRO_SERVICES}/summary`;
export const ROUTE_REGISTER = 'register';
export const ROUTE_PASSWORD = 'password';
export const ROUTE_CONFIRM_MAIL = 'confirm-mail';
export const ROUTE_COMPANY = 'company';
export const ROUTE_DELETE_ACCOUNT = 'delete-account';
export const ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS = 'requests';
export const ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS_WITH_ID = 'requests-with-id';
export const ROUTE_INFO_FEED = 'info-feed';
export const ROUTE_EMPLOYEES = 'employees';
export const ROUTE_APP_DOWNLOAD = 'app-download';
export const ROUTE_ADVERTISEMENTS = 'advertisements';
export const ROUTE_COMPANY_OVERVIEW = 'company-overview';
export const ROUTE_VERIFY = 'verify';
export const ROUTE_PAYPAL_APPROVED = 'paypal-approved';
export const ROUTE_PAYPAL_PAYMENT = 'pay-pal-payment';

/**
 * pb views
 */
export const ROUTE_COMPANIES = 'companies';
export const ROUTE_CONTAINER_TYPE_REQUESTS = 'container-types';
export const ROUTE_CONTAINER_TYPE_REQUESTS_WITH_ID = 'container-types-with-id';
export const ROUTE_BONIFICATION = 'bonification';

/**
 * general views that are always visible
 */
export const ROUTE_PROFILE = 'profile';
