import {ContainerState, RootState} from '@/interfaces/StoreStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import Container from "@/models/Container.model";
import ContainerEvent from "@/models/ContainerEvent.model";
import ContainerRepository from "@/api/repositories/ContainerRepository";

export const CONTAINER_STORE_NAME = 'containers';

export const enum ContainerStoreActions {
    GET_ALL = 'GET_ALL',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    GET_HISTORY = 'GET_HISTORY'
}

export const enum ContainerStoreMutations {
    SET_CONTAINERS = 'SET_CONTAINERS',
    SET_HISTORY = 'SET_HISTORY',
    ADD_CONTAINER = 'ADD_CONTAINER',
    UPDATE_CONTAINER = 'UPDATE_CONTAINER',
    CLEAR_STORE = 'CLEAR_STORE'
}

export const enum ContainerStoreGetters {
    CONTAINERS = 'CONTAINERS'
}

function initialContainerState(): ContainerState {
    return {
        // TODO: reintroduce multiple container types later
        containers: [Container.parseFromObject({id: 1, type: { name: 'StandardBehälter' }})],
        history: []
    };
}

const store: ContainerState = initialContainerState();

const actions: ActionTree<ContainerState, RootState> = {
    [ContainerStoreActions.GET_ALL]: async ({ commit }): Promise<Container[]> => {
        // TODO: make api call
        return store.containers;
    },
    [ContainerStoreActions.GET_HISTORY]: async ({ commit }, companyId: string): Promise<ContainerEvent[]> => {
        const response = await ContainerRepository.getContainerHistory(companyId);
        const history = response.data.map((e: any) => ContainerEvent.parseFromObject(e));

        commit(ContainerStoreMutations.SET_HISTORY, history);
        return history;
    },
    [ContainerStoreActions.CREATE]: async ({ commit }, container: Container): Promise<Container> => {
        commit(ContainerStoreMutations.ADD_CONTAINER, container);
        return container;
    },
    [ContainerStoreActions.UPDATE]: async ({ commit }, container: Container): Promise<Container> => {
        // TODO: add api call

        commit(ContainerStoreMutations.UPDATE_CONTAINER, container);
        return container;
    }
};

const mutations: MutationTree<ContainerState> = {
    [ContainerStoreMutations.SET_CONTAINERS]: (state: ContainerState, value: Container[]) => {
        state.containers = value;
    },
    [ContainerStoreMutations.SET_HISTORY]: (state: ContainerState, value: ContainerEvent[]) => {
        state.history = value;
    },
    [ContainerStoreMutations.ADD_CONTAINER]: (state: ContainerState, container: Container) => {
        state.containers.push(Container.parseFromObject({...container, id: state.containers.length}));
    },
    [ContainerStoreMutations.UPDATE_CONTAINER]: (state: ContainerState, container: Container) => {
        const index = state.containers.findIndex(c => c.id === container.id);
        state.containers.splice(index, 1, container);
    },
    [ContainerStoreMutations.CLEAR_STORE]: (state: ContainerState) => {
        // Merge rather than replace so we don't lose observers
        // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
        Object.assign(state, initialContainerState());
    }
};

const getters: GetterTree<ContainerState, RootState> = {
    [ContainerStoreGetters.CONTAINERS]: (state: ContainerState) => state.containers
};

const containerStore: Module<ContainerState, RootState> = {
    state: store,
    actions: actions,
    mutations: mutations,
    getters: getters,
};

export default containerStore;
