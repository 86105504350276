import Parseable from '@/misc/Parseable';
import ContainerType from "@/models/ContainerType.model";
import {ContainerStatus} from "@/enum/ContainerStatus.enum";
import Company from "@/models/Company";

export default class Container extends Parseable {
    public id!: string;
    public type!: ContainerType;
    public status!: ContainerStatus;
    public owner!: Company;

    public static parseFromObject(object: any): Container {
        const container = new Container();
        Object.assign(container, object);
        return container;
    }

    public parseToObject(): Partial<Container> {
        return { ...this };
    }
}
