import Parseable from "@/misc/Parseable";

export default class PenaltyContainerType extends Parseable {
    public id!: string;
    public penaltyRate!: number;
    public penaltyMaxWeeks!: number;
    public lastPenaltyRate!: number;
    public name!: string;
    public height!: number;
    public width!: number;
    public depth!: number;
    public isDefaultType!: boolean;
    public hasDynamicStock!: boolean;
    public status!: string;

    parseToObject(): any {
        return {...this};
    }

    /**
     * parses an object to a news object
     * @param object
     */
    public static parseFromObject(object: any): PenaltyContainerType {
        const penaltyContainerType = new PenaltyContainerType();
        Object.assign(penaltyContainerType, object);
        return penaltyContainerType;
    }
}