import {AccountType} from '@/enum/AccountType.enum';
import Parseable from '@/misc/Parseable';

export default class Account extends Parseable {
  public id!: string;
  public username!: string;
  public password!: string;
  public role!: AccountType;
  public email?: string;
  public twoFactorEnabled?: boolean;

  public accountNonExpired!: boolean;
  public accountNonLocked!: boolean;
  public enabled!: boolean;

  public static parseFromObject(object: any): Account {
      const account = new Account();
      Object.assign(account, object);
      return account;
  }

  public parseToObject(): Partial<Account> {
      return { ...this };
  }

  public get isPB(): boolean {
      return this.role === AccountType.PB;
  }
}

