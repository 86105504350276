import Parseable from '@/misc/Parseable';
import Company from "@/models/Company";
import Account from "@/models/Account";

export default class Employee extends Parseable {
    public id!: string;
    public company!: Company;
    public account!: Account;
    public firstName?: string;
    public lastName?: string;

    public static parseFromObject(object: any): Employee {
        const employee = new Employee();
        Object.assign(employee, object);
        employee.company = Company.parseFromObject(employee.company);
        employee.account = Account.parseFromObject(employee.account);
        return employee;
    }

    public parseToObject(): Partial<Employee> {
        return { ...this };
    }
}
