import Parseable from "@/misc/Parseable";
import PenaltyContainerType from "@/models/PenaltyContainerType";
import {PenaltyPaymentType} from "@/enum/PenaltyPaymentType.enum";

export default class PenaltyStatus extends Parseable {
    public date!: string;
    public containerType!: PenaltyContainerType;
    public income!: number;
    public type!: PenaltyPaymentType;

    parseToObject(): any {
        return {...this};
    }

    /**
     * parses an object to a news object
     * @param object
     */
    public static parseFromObject(object: any): PenaltyStatus {
        const penaltyStatus = new PenaltyStatus();
        Object.assign(penaltyStatus, object);
        penaltyStatus.containerType = PenaltyContainerType.parseFromObject(penaltyStatus.containerType);
        return penaltyStatus;
    }

}