import Parseable from '@/misc/Parseable';
import Company from "@/models/Company";
import {AccountType} from "@/enum/AccountType.enum";
import Container from "@/models/Container.model";
import {EventStatus} from "@/enum/EventStatus.enum";
import User from "@/models/User.model";
import Transaction from "@/models/Transaction.model";

export default class ContainerEvent extends Parseable {
    public container?: Container;
    public eventStatus?: EventStatus;
    public eventTime?: string;
    public renter?: User;
    public transaction?: Transaction;

    public company?: Company;
    public accountId?: string;
    public role?: AccountType;

    public static parseFromObject(object: any): ContainerEvent {
        const containerEvent = new ContainerEvent();
        Object.assign(containerEvent, object);
        containerEvent.container = Container.parseFromObject(containerEvent.container);
        containerEvent.transaction = Transaction.parseFromObject(containerEvent.transaction);
        containerEvent.company = Company.parseFromObject(containerEvent.company);
        return containerEvent;
    }

    public parseToObject(): Partial<ContainerEvent> {
        return { ...this };
    }
}
