import { AxiosResponse } from 'axios';
import { AUTH_CLIENT } from '../misc/AuthClient';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import Company from "@/models/Company";
import OnBoardingProgress from "@/models/OnBoardingProgress";

/**
 * deals with all api requests that have to do with companies
 */
export default class CompanyRepository {
    private static baseUrl: string = 'api/company';

    public static getCompanies(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}`);
    }

    /**
     * gets a company object by the id
     * @param companyId
     */
    public static getCompanyById(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/${companyId}`);
    }

    /**
     * updates the company by an id
     * @param companyId
     * @param payload
     */
    public static updateCompany(companyId: string, payload: Company): Promise<AxiosResponse> {
        return ENTITY_CLIENT.put(`${this.baseUrl}/${companyId}`, payload);
    }

    /**
     * deletes a company by id
     * @param companyId
     */
    public static deleteCompany(companyId: string): Promise<AxiosResponse> {
        return AUTH_CLIENT.delete(`${this.baseUrl}/${companyId}`);
    }

    /**
     * gets all employees of company with the passed id
     * @param companyId
     */
    public static getEmployeesOfCompany(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/${companyId}/employees`);
    }

    /**
     * Gets the daily income of the company
     * @param companyId that represents the id of the company
     */
    public static getIncomeByCompanyId(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/income/history/${companyId}`);
    }

    /**
     * Gets the chart information about the income of the company
     */
    public static getChartInfoAboutIncomeByCompanyId(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/income/history/chart/${companyId}`);
    }

    public static updateCompanyProgress(companyId: string, companyProgress: OnBoardingProgress): Promise<AxiosResponse> {
        return ENTITY_CLIENT.put(`${this.baseUrl}/${companyId}/progress`, companyProgress);
    }
}
