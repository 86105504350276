
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {VALIDATION_STORE_NAME, ValidationStoreActions} from "@/store/validation.store";
import Validations from "@/models/Validations.model";

const ValidationStore = namespace(VALIDATION_STORE_NAME);

@Component
export default class App extends Vue {
  private isLoading = true;

  @ValidationStore.Action(ValidationStoreActions.FETCH_VALIDATIONS)
  private getValidation!:() => Promise<Validations>;

  async created() {
    try {
      // sets fallback values as default, then loads validation values
      Validations.setFallbackValues();
      await this.getValidation();
    } finally {
      this.isLoading = false;
    }
  }
}
