import { AccountType } from '@/enum/AccountType.enum';
import Account from '@/models/Account';
import store from '@/store';
import { AuthStoreGetters, AUTH_STORE_NAME } from '@/store/auth.store';

const AuthorizationPlugin = {
  install(Vue: any, options: any = {}) {
    const isLoggedIn = (): boolean => {
      const token = store.getters[`${AUTH_STORE_NAME}/${AuthStoreGetters.TOKEN}`];
      return token != undefined;
    };
    Vue.prototype.$isLoggedIn = isLoggedIn;
    Vue.isLoggedIn = isLoggedIn;

    // Needs to be a function in order to be reactive
    const getCurrentAccount = (): Account | undefined => { // TODO: Declare function for each entity (user, employee, restaurateur)
      const account = new Account();
      Object.assign(account, store.getters[`${AUTH_STORE_NAME}/${AuthStoreGetters.CURRENT_ACCOUNT}`]);
      return account;
    };
    Vue.prototype.$getCurrentAccount = getCurrentAccount;
    Vue.getCurrentAccount = getCurrentAccount;

    const hasRole = (role: AccountType) => {
      const account: Account | undefined = getCurrentAccount();
      return account && account.role == role;
    };
    Vue.prototype.$hasRole = hasRole;
    Vue.hasRole = hasRole;

    const hasAnyRole = (roles: AccountType[]) => {
      const user: Account | undefined = getCurrentAccount();
      return user && roles.indexOf(user.role) != -1;
    };
    Vue.prototype.$hasAnyRole = hasAnyRole;
    Vue.hasAnyRole = hasAnyRole;
  }
};

export default AuthorizationPlugin;
