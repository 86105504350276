import Vue from 'vue';
import VueRouter from 'vue-router';
import store, { StoreActions } from '@/store';
import {
  ROUTE_CONTAINERS,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_PASSWORD,
  ROUTE_REGISTER,
  ROUTE_INFO_FEED,
  ROUTE_PENALTY_PAYMENTS,
  ROUTE_HISTORY,
  ROUTE_COMPANIES,
  ROUTE_COMPANY,
  ROUTE_EMPLOYEES,
  ROUTE_CONTAINERS_SUMMARY,
  ROUTE_CONTAINERS_OVERVIEW,
  ROUTE_CONTAINER_TYPE_REQUESTS,
  ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS,
  ROUTE_CONTAINER_TYPE_REQUESTS_WITH_ID,
  ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS_WITH_ID,
  ROUTE_PROFILE,
  ROUTE_CONFIRM_MAIL,
  ROUTE_MICRO_SERVICES,
  ROUTE_MICRO_SERVICES_OVERVIEW,
  ROUTE_MICRO_SERVICE_SUMMARY,
  ROUTE_ADVERTISEMENTS,
  ROUTE_BONIFICATION,
  ROUTE_DELETE_ACCOUNT,
  ROUTE_COMPANY_OVERVIEW,
  ROUTE_VERIFY, ROUTE_APP_DOWNLOAD, ROUTE_PAYPAL_PAYMENT, ROUTE_CONNECT_PAYPAL, ROUTE_PAYPAL_APPROVED,
} from '@/router/routes';
import {AuthStoreGetters} from "@/store/auth.store";

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: ROUTE_LOGIN,
    component: () => import(/* webpackChunkName: "login" */ '../views/Authentication/Login.view.vue'),
  },
  {
    path: '/register',
    name: ROUTE_REGISTER,
    component: () => import(/* webpackChunkName: "register" */ '../views/Authentication/Register.view.vue'),
  },
  {
    path: '/verify-account',
    name: ROUTE_VERIFY,
    component: () => import(/* webpackChunkName: "verify" */ '../views/Authentication/Verify.view.vue'),
  },
  {
    path: '/paypal-approved',
    name: ROUTE_PAYPAL_APPROVED,
    component: () => import(/* webpackChunkName: "paypal-approved" */ '../views/Payment/PaymentSettled.view.vue'),
  },
  {
    path: '/pay/:accountId',
    name: ROUTE_PAYPAL_PAYMENT,
    component: () => import(/* webpackChunkName: "pay-with-paypal" */ '../views/Payment/PayPayPal.view.vue'),
  },
  {
    path: '/password',
    name: ROUTE_PASSWORD,
    component: () => import(/* webpackChunkName: "password" */ '../views/Authentication/Password.view.vue'),
  },
  {
    path: '/confirm-deletion',
    name: ROUTE_DELETE_ACCOUNT,
    component: () => import(/* webpackChunkName: "confirm-deletion" */ '../views/Authentication/DeleteAccountConfirm.view.vue'),
  },
  {
    path: '/confirm-email',
    name: ROUTE_CONFIRM_MAIL,
    component: () => import(/* webpackChunkName: "confirm-email" */ '../views/ConfirmMail.view.vue'),
  },
  {
    path: '/payments/paypal/connect/return',
    name: ROUTE_CONNECT_PAYPAL,
    component: () => import(/* webpackChunkName: "connect-paypal" */ '../views/PaypalApproval.view.vue'),
  },
  {
    path: '/app',
    name: 'app',
    component: () => import(/* webpackChunkName: "app-deeplink" */ '../views/App.view.vue'),
  },
  {
    path: '/home',
    name: ROUTE_HOME,
    beforeEnter: async (to: any, from: any, next: any) => {
      // Check if token is set. If not, redirect to login
      if (store.getters[`auth/${AuthStoreGetters.TOKEN}`]) {
        await next();
      } else {
        await store.dispatch(StoreActions.CLEAR_ALL_STORES);
        await next({ name: ROUTE_LOGIN });
      }
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.view.vue'),
    children: [
      {
        path: '/company/:id?',
        name: ROUTE_COMPANY,
        component: () => import(/* webpackChunkName: "company" */ '../views/Company/Company.view.vue'),
        redirect: { name: ROUTE_COMPANY_OVERVIEW },
        props: true,
        children: [
          {
            path: 'overview',
            name: ROUTE_COMPANY_OVERVIEW,
            component: () => import(/* webpackChunkName: "company" */ '../views/Company/CompanyOverview.view.vue'),
          },
          {
            path: 'penalty_payment',
            name: ROUTE_PENALTY_PAYMENTS,
            component: () => import(/* webpackChunkName: "penalty payment" */ '../views/PenaltyPayment/Penalty_Payment.view.vue'),
          },
          {
            path: 'containers',
            name: ROUTE_CONTAINERS,
            component: () => import(/* webpackChunkName: "ContainerType" */ '../views/ContainerType/Containers.view.vue'),
            redirect: { name: ROUTE_CONTAINERS_OVERVIEW },
            children: [
              {
                path: 'overview/:containerTypeId?',
                name: ROUTE_CONTAINERS_OVERVIEW,
                component: () => import(/* webpackChunkName: "containersOverview" */ '../views/ContainerType/ContainersOverview.view.vue'),
              },
              {
                path: 'summary/:containerTypeId',
                name: ROUTE_CONTAINERS_SUMMARY,
                component: () => import(/* webpackChunkName: "rentedContainers" */ '../views/ContainerType/RentedContainerStatistics.view.vue'),
              },
            ]
          },
          {
            path: 'requests',
            name: ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS,
            component: () => import(/* webpackChunkName: "company-requests" */ '../views/ContainerType/CompanyRequestedContainers.view.vue'),
          },
          {
            path: 'requests/:containerTypeId?',
            name: ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS_WITH_ID,
            component: () => import(/* webpackChunkName: "company-requests-with-id" */ '../views/ContainerType/CompanyRequestedContainers.view.vue'),
          },
          {
            path: 'micro-services',
            name: ROUTE_MICRO_SERVICES,
            component: () => import(/* webpackChunkName: "microServices" */ '../views/MicroServices/MicroServices.view.vue'),
            redirect: { name: ROUTE_MICRO_SERVICES_OVERVIEW },
            children: [
              {
                path: 'overview/:microserviceId?',
                name: ROUTE_MICRO_SERVICES_OVERVIEW,
                component: () => import(/* webpackChunkName: "microserviceOverview" */ '../views/MicroServices/MicroServicesOverview.view.vue'),
              },
              {
                path: 'summary/:microserviceId?',
                name: ROUTE_MICRO_SERVICE_SUMMARY,
                component: () => import(/* webpackChunkName: "microserviceSummary" */ '../views/MicroServices/MicroServiceSummary.view.vue'),
              },
            ]
          },
          {
            path: 'history',
            name: ROUTE_HISTORY,
            component: () => import(/* webpackChunkName: "history" */ '../views/History/History.view.vue'),
          },
          {
            path: 'info-feed',
            name: ROUTE_INFO_FEED,
            component: () => import(/* webpackChunkName: "info-feed" */ '../views/InfoFeed/InfoFeed.view.vue'),
          },
          {
            path: 'employees',
            name: ROUTE_EMPLOYEES,
            component: () => import(/* webpackChunkName: "employee-overview" */ '../views/Employee/EmployeeOverview.view.vue'),
          },
          {
            path: 'app-download',
            name: ROUTE_APP_DOWNLOAD,
            component: () => import(/* webpackChunkName: "app-download" */ '../views/AppDownload/AppDownload.view.vue'),
          },
        ],
      },
      {
        path: '/admin/companies/:id?',
        name: ROUTE_COMPANIES,
        component: () => import(/* webpackChunkName: "companies" */ '../views/AdminViews/Companies.view.vue'),
      },
      {
        path: '/admin/advertisements/:id?',
        name: ROUTE_ADVERTISEMENTS,
        component: () => import(/* webpackChunkName: "advertisements" */ '../views/AdminViews/Advertisement.view.vue'),
      },
      {
        path: '/admin/bonification/:id?',
        name: ROUTE_BONIFICATION,
        component: () => import(/* webpackChunkName: "bonification" */ '../views/AdminViews/Bonification/Bonification.view.vue'),
      },
      {
        path: '/admin/container-type-requests/:id?',
        name: ROUTE_CONTAINER_TYPE_REQUESTS,
        component: () => import(/* webpackChunkName: "container-types" */ '../views/AdminViews/ContainerTypeRequests.view.vue'),
      },
      {
        path: '/admin/container-type-requests/:containerTypeId',
        name: ROUTE_CONTAINER_TYPE_REQUESTS_WITH_ID,
        component: () => import(/* webpackChunkName: "container-types-with-id" */ '../views/AdminViews/ContainerTypeRequests.view.vue'),
      },
      {
        path: '/my-profile/:id?',
        name: ROUTE_PROFILE,
        component: () => import(/* webpackChunkName: "edit-profile.view" */ '../views/Authentication/EditProfile.view.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: { name: ROUTE_HOME }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
