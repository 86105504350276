import { FeedState, RootState} from '@/interfaces/StoreStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import News from "@/models/News.model";
import NewsRepository from "@/api/repositories/NewsRepository";

export const INFO_FEED_STORE_NAME = 'infoFeeds';

export const enum FeedStoreActions {
    GET = 'GET',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

export const enum FeedStoreMutations {
    SET_FEED = 'SET_FEED',
    ADD_FEED = 'UPDATE_FEED',
    REMOVE_FEED = 'REMOVE_FEED',
    CLEAR_STORE = 'CLEAR_STORE'
}

export const enum FeedStoreGetters {
    FEED = 'FEED'
}

function initialFeedState(): FeedState {
    return {
        feeds: []
    };
}

const store: FeedState = initialFeedState();

const actions: ActionTree<FeedState, RootState> = {
    [FeedStoreActions.GET]: async ({ commit }, companyId: string): Promise<News[]> => {
        // fetches the news of the company
        const response = await NewsRepository.getNews(companyId);

        // maps the api response as news objects and stores them in the feed store
        const news = response.data.map((n: any) => News.parseFromObject(n));
        commit(FeedStoreMutations.SET_FEED, news);

        // returns the fetched news
        return news;
    },
    [FeedStoreActions.CREATE]: async ({ commit }, news: News): Promise<News> => {
        // posts the feed to the api and transforms the incoming news into a news object
        const createdNews = await NewsRepository.createNews(news);
        const newFeed = News.parseFromObject(createdNews.data);

        // updates the store to also hold the newest feed and returns it
        commit(FeedStoreMutations.ADD_FEED, newFeed);
        return newFeed;
    },
    [FeedStoreActions.UPDATE]: async ({ commit }, feed: News): Promise<News> => {
        // updates the news in the db, gets the updated news and parses it as news object
        const response = await NewsRepository.updateNews(feed);
        const updatedNews = News.parseFromObject(response.data);

        // gets the feeds, finds the outdated element and replaces it with the updated one
        const updatedFeeds = store.feeds;
        const index = updatedFeeds.findIndex((f) => f.id === feed.id);
        updatedFeeds.splice(index, 0, updatedNews);

        // updates the feed array in the store and returns the updated feed
        commit(FeedStoreMutations.SET_FEED, updatedFeeds);
        return feed;
    },
    [FeedStoreActions.DELETE]: async ({ commit }, newsId: string): Promise<string> => {
        // deletes the news in the db
        await NewsRepository.deleteNews(newsId);

        // removes the deleted news from the store
        commit(FeedStoreMutations.REMOVE_FEED, newsId);
        return newsId;
    }
};

const mutations: MutationTree<FeedState> = {
    [FeedStoreMutations.SET_FEED]: (state: FeedState, value: News[]) => {
        state.feeds = value;
    },
    [FeedStoreMutations.ADD_FEED]: (state: FeedState, value: News) => {
        state.feeds.push(value);
    },
    [FeedStoreMutations.REMOVE_FEED]: (state: FeedState, value: string) => {
        // finds the index of the news that was deleted
        const index = state.feeds.findIndex((f) => f.id === value);

        // removes the deleted item from the store
        state.feeds.splice(index, 1);
    },
    [FeedStoreMutations.CLEAR_STORE]: (state: FeedState) => {
      // Merge rather than replace so we don't lose observers
      // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
      Object.assign(state, initialFeedState());
    }
};

const getters: GetterTree<FeedState, RootState> = {
    [FeedStoreGetters.FEED]: (state: FeedState) => state.feeds
};

const feedStore: Module<FeedState, RootState> = {
    state: store,
    actions: actions,
    mutations: mutations,
    getters: getters,
};

export default feedStore;
