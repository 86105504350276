export default class Validations {
    public static email: number;
    public static password: number;
    public static fcmToken: number;
    public static street: number;
    public static houseNo: number;
    public static zip: number;
    public static city: number;
    public static country: number;
    public static phone: number;
    public static companyName: number;
    public static companyLegalName: number;
    public static firstName: number;
    public static lastName: number;
    public static containerTypeName: number;
    public static containerTypeDescription: number;
    public static containerTypeInternalInstruction: number;
    public static newsDescription: number;
    public static newsSubTitle: number;
    public static ssoIdentifier: number;
    public static ssoEmail: number;

    public static parseFromObject(object: any): void {
        Object.assign(this, object);
    }

    /**
     * sets the validations with some fallback values in case the request couldn't be executed successfully
     */
    public static setFallbackValues() {
        this.email = 100;
        this.password = 255;
        this.fcmToken = 255;
        this.street = 100;
        this.houseNo = 10;
        this.zip = 10;
        this.city = 100;
        this.country = 100;
        this.phone = 30;
        this.companyLegalName = 255;
        this.companyName = 255;
        this.firstName = 100;
        this.lastName = 100;
        this.containerTypeName = 100;
        this.containerTypeDescription = 255;
        this.containerTypeInternalInstruction = 255;
        this.newsDescription = 250;
        this.newsSubTitle = 60;
        this.ssoEmail = 255;
        this.ssoIdentifier = 255;
    }
}
