import Parseable from '@/misc/Parseable';
import Company from "@/models/Company";
import {DateTime} from "luxon";
import {NewsType} from "@/enum/NewsType.enum";
import {NewsIcon} from "@/enum/NewsIcon.enum";
import NewsBackground from "@/models/NewsBackground";

export default class News extends Parseable {
    public company!: Company;
    public createdOn!: DateTime;
    public id!: string;
    public description?: string;
    public subTitle!: string;
    public price!: number;
    public type!: NewsType;
    public icons!: NewsIcon[];
    public background!: NewsBackground;
    public validFrom?: string;

    /**
     * parses an object to a news object
     * @param object
     */
    public static parseFromObject(object: any): News {
        const news = new News();
        Object.assign(news, object);
        return news;
    }

    public parseToObject(): Partial<News> {
        return { ...this };
    }
}
