import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * repository that provides all the api requests that deal with ContainerType
 */
export default class ContainerRepository {
    private static baseUrl: string = 'api/container';

    /**
     * gets a company object by the id
     * @param companyId
     */
    public static getCompanyById(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/${companyId}`);
    }

    /**
     * gets the status of the companies ContainerType
     * @param companyId id of the company that should be shown
     */
    public static getCompanyContainerStatusInfo(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/status?companyId=${companyId}`);
    }

    /**
     * returns the container history of the company
     * @param companyId
     */
    public static getContainerHistory(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/history?companyId=${companyId}`);
    }
}
