import {AxiosResponse} from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import ContainerType from "@/models/ContainerType.model";
import {ContainerTypeStatus} from "@/enum/ContainerTypeStatus";

export default class ContainerTypeRepository {
    private static baseUrl: string = 'api/type';

    public static createContainerType(payload: { companyId: string, individualRequest: boolean,
        containerType: Partial<ContainerType>, lastPenaltyRate: number }): Promise<AxiosResponse<ContainerType>> {
        return ENTITY_CLIENT.post(`${this.baseUrl}`, {
            companyId: payload.companyId,
            name: payload.containerType.name,
            individualRequest: payload.individualRequest,
            lastPenaltyRate: payload.lastPenaltyRate,
            description: payload.containerType.description,
            internalInstruction: payload.containerType.internalInstruction,
            stock: payload.containerType.stock
        });
    }

    /**
     * Get All Container Types
     * @param companyId If Company Id is given then only their types will be loaded, if none is given all of them are loaded
     */
    public static getAll(companyId: string | undefined): Promise<AxiosResponse<ContainerType[]>> {
        // If no Company was given load all
        if (!companyId) {
            return ENTITY_CLIENT.get(`${this.baseUrl}`);
        }

        // if company was given load only their types
        return ENTITY_CLIENT.get(`${this.baseUrl}/${companyId}/all`);
    }

    /**
     * Update a Specific ContainerType with new Data
     * @param payload Payload needs to include a containerTypeId and the changed values as containerType Object
     */
    public static updateContainerType(payload: { companyId: string, containerTypeId: string,
        containerType: Partial<ContainerType>, lastPenaltyRate: number }): Promise<AxiosResponse<ContainerType>> {
        return ENTITY_CLIENT.put(`${this.baseUrl}/${payload.containerTypeId}?companyId=${payload.companyId}`, {
            name: payload.containerType.name,
            lastPenaltyRate: payload.lastPenaltyRate,
            description: payload.containerType.description,
            internalInstruction: payload.containerType.internalInstruction,
            stock: payload.containerType.stock
        });
    }

    /**
     * Delete a Specific Container Type
     * @param containerTypeId Container Type Id to Remove
     */
    public static deleteContainerType(containerTypeId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.delete(`${this.baseUrl}/${containerTypeId}`);
    }

    /**
     * gets all requested container types
     */
    public static getRequestedContainerTypes(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/requested/all`);
    }

    /**
     * gets all requested container types
     */
    public static getRequestedCompanyContainerTypes(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/${companyId}/requested/all`);
    }

    /**
     * changes the status of the container type
     * @param id of the container type that should be edited
     * @param accepted
     */
    public static changeContainerTypeStatus(id: string, accepted: boolean) {
        return ENTITY_CLIENT.put(`${this.baseUrl}/${id}/handle`, {accepted});
    }

    public static changeContainerTypeStock(id: string, containerTypeId: string, stock: number) {
        return ENTITY_CLIENT.post(`${this.baseUrl}/${containerTypeId}/${id}/stock?quantity=${stock}`);
    }
}
