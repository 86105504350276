import { RootState, ApplicationState } from '@/interfaces/StoreStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import Company from "@/models/Company";
import CompanyRepository from "@/api/repositories/CompanyRepository";
import {CompanyStoreActions, CompanyStoreMutations} from "@/store/company.store";

export const APPLICATION_STORE_NAME = 'application';

export const enum ApplicationStoreActions {
  GET_CURRENT_COMPANY = 'GET_CURRENT_COMPANY',
  UPDATE_CURRENT_COMPANY = 'UPDATE_CURRENT_COMPANY'
}

export const enum ApplicationStoreMutations {
  SET_COMPANY = 'SET_COMPANY',
  CLEAR_STORE = 'CLEAR_STORE'
}

export const enum ApplicationStoreGetters {
  CURRENT_COMPANY = 'CURRENT_COMPANY'
}

function initialApplicationState(): ApplicationState {
  return {
    currentCompany: undefined,
  };
}

const store: ApplicationState = initialApplicationState();

const actions: ActionTree<ApplicationState, RootState> = {
  [ApplicationStoreActions.GET_CURRENT_COMPANY]: async ({ commit },  id: string): Promise<Company> => {
    // fetches the news of the company
    const response = await CompanyRepository.getCompanyById(id);

    // maps the api response as news objects and stores them in the feed store
    const company =  Company.parseFromObject(response.data);
    commit(ApplicationStoreMutations.SET_COMPANY, company);

    // returns the fetched news
    return company;
  },
  [ApplicationStoreActions.UPDATE_CURRENT_COMPANY]: async ({ commit }, payload: {id: string, company: Company}): Promise<Company> => {
    // fetches the news of the company
    const response = await CompanyRepository.updateCompany(payload.id, payload.company);

    // maps the api response as news objects and stores them in the feed store
    const company =  Company.parseFromObject(response.data);
    commit(ApplicationStoreMutations.SET_COMPANY, company);

    // returns the fetched news
    return company;
  },
};

const mutations: MutationTree<ApplicationState> = {
  [ApplicationStoreMutations.SET_COMPANY]: (state: ApplicationState, value: Company) => {
    state.currentCompany = value;
  },
  [ApplicationStoreMutations.CLEAR_STORE]: (state: ApplicationState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialApplicationState());
  },
};

const getters: GetterTree<ApplicationState, RootState> = {
  [ApplicationStoreGetters.CURRENT_COMPANY]: (state: ApplicationState) => state.currentCompany,
};

const applicationStore: Module<ApplicationState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default applicationStore;
