import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import Restaurateur from "@/models/Restaurateur";

/**
 * repository that deals with all the api requests that have to do with restauranteurs
 */
export default class RestauranteurRepository {
    private static baseUrl: string = 'api/restaurateur';

    /**
     * gets the user based on the token that is used
     */
    public static getRestauranteurByToken(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/auth`);
    }

    public static updateRestauranteur(restauranteur: Restaurateur): Promise<AxiosResponse> {
        return ENTITY_CLIENT.put(`${this.baseUrl}/${restauranteur.id}`, restauranteur);
    }
}
