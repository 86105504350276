import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * deals with all the requests that have to do with accounts
 */
export default class AccountRepository {
    /**
     * gets an account based on the token
     */
    public static getMyAccount(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get('api/account/getMyAccount');
    }

    /**
     * gets an account based on the token
     */
    public static verifyAccount(token: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`api/account/verify?token=${token}`);
    }

    /**
     * changes the password of the user
     * @param oldPassword
     * @param newPassword
     */
    public static changePassword(oldPassword: string, newPassword: string) {
        return ENTITY_CLIENT.post('api/account/changePassword', {
            oldPassword,
            newPassword
        });
    }
    
    /** 
     * update user email
     */
    public static updateEmail(email: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.put('api/account/update', {email});
    }

    /**
     * resends verification mail to email
     */
    public static resendVerification(email: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`api/account/verification-request?email=${email}`);
    }
}
