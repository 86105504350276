import Parseable from '@/misc/Parseable';

export default class Transaction extends Parseable {
    public amountDueToCompany?: number;
    public companyId?: string;
    public companyIncomeOffset?: number;
    public companyIncomeRate?: number;
    public containerId?: string;
    public deadline?: string;
    public id?: string;
    public lastPenaltyRate?: number;
    public penaltyMaxWeeks?: number;
    public penaltyRate?: number;
    public rentalOperationId?: string;
    public rentedOn?: string;
    public returnOperationId?: string;
    public returnedOn?: string;
    public settledPaymentAmount?: number;
    public userId?: string;


    public static parseFromObject(object: any): Transaction {
        const transaction = new Transaction();
        Object.assign(transaction, object);
        return transaction;
    }

    public parseToObject(): Partial<Transaction> {
        return { ...this };
    }
}
