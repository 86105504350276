import Parseable from '@/misc/Parseable';
import Company from "@/models/Company";
import Account from "@/models/Account";
import {AccountPosition} from "@/enum/AccountPosition.enum";
import {AccountTitle} from "@/enum/AccountTitle.enum";

export default class Restaurateur extends Parseable {
    public id?: string;
    public account?: Account;
    public company?: Company;
    public firstName?: string;
    public lastName?: string;

    public position!: AccountPosition;
    public title!: AccountTitle;

    public static parseFromObject(object: any): Restaurateur {
        const restaurateur = new Restaurateur();
        Object.assign(restaurateur, object);
        restaurateur.company = Company.parseFromObject(restaurateur.company);
        restaurateur.account = Account.parseFromObject(restaurateur.account);
        return restaurateur;
    }

    public parseToObject(): Partial<Restaurateur> {
        return { ...this };
    }
}
