import { RootState } from '@/interfaces/StoreStateInterfaces';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import authStore, { AUTH_STORE_NAME } from './auth.store';
import containerStore, {CONTAINER_STORE_NAME} from './container.store';
import applicationStore, { APPLICATION_STORE_NAME } from './application.store';
import VuexPersistence from 'vuex-persist';
import feedStore, {INFO_FEED_STORE_NAME} from "@/store/feed.store";
import penaltyStore, {PENALTY_STORE_NAME} from "@/store/penalty.store";
import companyStore, {COMPANY_STORE_NAME} from "@/store/company.store";
import containerTypeStore, {CONTAINER_TYPE_STORE_NAME} from "@/store/container-type.store";
import validationStore, {VALIDATION_STORE_NAME} from "@/store/validation.store";

Vue.use(Vuex);

export const enum StoreActions {
  CLEAR_ALL_STORES = 'CLEAR_ALL_STORES'
}

// Persists auth and application module in local storage:
const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  key: process.env.VUE_APP_VUEX_KEY,
  modules: [AUTH_STORE_NAME, VALIDATION_STORE_NAME]
});

const store: StoreOptions<RootState> = {
  modules: {
    [APPLICATION_STORE_NAME]: {
      namespaced: true,
      ...applicationStore,
    },
    [AUTH_STORE_NAME]: {
      namespaced: true,
      ...authStore,
    },
    [VALIDATION_STORE_NAME]: {
      namespaced: true,
      ...validationStore,
    },
    [CONTAINER_STORE_NAME]: {
      namespaced: true,
      ...containerStore,
    },
    [INFO_FEED_STORE_NAME]: {
      namespaced: true,
      ...feedStore,
    },
    [PENALTY_STORE_NAME]: {
      namespaced: true,
      ...penaltyStore,
    },
    [COMPANY_STORE_NAME]: {
      namespaced: true,
      ...companyStore,
    },
    [CONTAINER_TYPE_STORE_NAME]: {
      namespaced: true,
      ...containerTypeStore,
    },
  },
  plugins: [vuexLocal.plugin],
  actions: {
    [StoreActions.CLEAR_ALL_STORES]: ({ commit }) => {
      Object.keys(store.modules!)
        .forEach((key: string) => commit(`${key}/CLEAR_STORE`));
    }
  }
};

export default new Vuex.Store<RootState>(store);
