import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // Colors
        primary: '#CBFD43',
        'dark-green': '#104035',
        'light-dark-green': '#1B5044',
        secondary: '#50C800',
        accent: '#82B1FF',
        error: '#FF5F56',
        info: '#01c1e5',
        success: '#0BBD58',
        success2: "#EAF6EF",
        success3: '#B8E0C9',
        'light-orange': "#EAF6EF",
        'light-orange-border': "#B8E0C9",
        orange: '#ECD049',
        warning: '#FFC107',
        background: '#f5f5f7',
        gray: '#e5e5e5',
        surface: '#ffffff',
        closeButton: '#8E8E9B',
        shadow: '#cccccc',
        'border-gray': '#dcdce5',
        border: '#ededed',
        rating: '#fcd25b',
        scrollbar: '#d5d7dd',
        'app-bar': '#49ab79',
        'navigation-drawer': '#f2f2f2',
        // Fonts
        'dark': '#202A47',
        'light-on-background': '#111827',
        'on-background': '#000000',
        'on-surface': '#000000',
        'on-primary': '#ffffff',
        'on-app-bar': '#ffffff',
      },
    },
  },
});
