import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * repository that deals with all the api requests that have to do with platform operators
 */
export default class PlatformOperatorRepository {
    private static baseUrl: string = 'api/platform-operator';

    /**
     * gets the user based on the token that is used
     */
    public static getPBByToken(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/auth`);
    }
}
