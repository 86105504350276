import {PenaltyState, RootState} from '@/interfaces/StoreStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import ContainerStatusInfo from "@/models/ContainerStatusInfo.model";
import CompanyRepository from "@/api/repositories/CompanyRepository";
import PenaltyPayment from "@/models/PenaltyPayment";
import PenaltyStatus from "@/models/PenaltyStatus";

export const PENALTY_STORE_NAME = 'penalties';

export const enum PenaltyStoreActions {
    GET_ALL = 'GET_ALL',
}

export const enum PenaltyStoreMutations {
    SET_PENALTIES = 'SET_CONTAINERS',
    CLEAR_STORE = 'CLEAR_STORE'
}

export const enum PenaltyStoreGetters {
    PENALTIES = 'PENALTIES'
}

function initialPenaltyState(): PenaltyState {
    return {
        penalties: []
    };
}

const store: PenaltyState = initialPenaltyState();

const actions: ActionTree<PenaltyState, RootState> = {
    [PenaltyStoreActions.GET_ALL]: async ({commit}, companyId: string): Promise<PenaltyStatus[]> => {

        // fetches all penalty information from the db
        const response = await CompanyRepository.getIncomeByCompanyId(companyId);
        // parses every response as a container status info object and checks if the penalty property is set
        const penaltyPayments = PenaltyPayment.parseFromObject(response.data);

        const penalties = penaltyPayments.history.map((p) => PenaltyStatus.parseFromObject(p));

        const filteredResponse = penalties.filter((p) => p.income && p.income > 0);

        // stores the filtered list in the store and returns it
        commit(PenaltyStoreMutations.SET_PENALTIES, filteredResponse);
        return filteredResponse;
    }
};

const mutations: MutationTree<PenaltyState> = {
    [PenaltyStoreMutations.SET_PENALTIES]: (state: PenaltyState, value: ContainerStatusInfo[]) => {
        state.penalties = value;
    },
    [PenaltyStoreMutations.CLEAR_STORE]: (state: PenaltyState) => {
        // Merge rather than replace so we don't lose observers
        // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
        Object.assign(state, initialPenaltyState());
    }
};

const getters: GetterTree<PenaltyState, RootState> = {
    [PenaltyStoreGetters.PENALTIES]: (state: PenaltyState) => state.penalties
};

const penaltyStore: Module<PenaltyState, RootState> = {
    state: store,
    actions: actions,
    mutations: mutations,
    getters: getters,
};

export default penaltyStore;
