import Parseable from '@/misc/Parseable';
import PenaltyStatus from "@/models/PenaltyStatus";

export default class PenaltyPayment extends Parseable {
    public balance!: number;
    public history!: PenaltyStatus[];

    public static parseFromObject(object: any): PenaltyPayment {
        const container = new PenaltyPayment();
        Object.assign(container, object);
        container.history = <PenaltyStatus[]>PenaltyStatus.parseFromArray(container.history!);
        return container;
    }

    public parseToObject(): Partial<PenaltyPayment> {
        return { ...this };
    }
}
