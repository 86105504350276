import { AxiosResponse } from 'axios';
import { AUTH_CLIENT } from '../misc/AuthClient';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

export default class AuthRepository {
  public static getAccessToken(payload: {username: string, password: string, code?: string}): Promise<AxiosResponse> {
    const data = new FormData();

    data.append('grant_type', 'password');
    data.append('password', payload.password);
    data.append('username', payload.username);
    data.append('fcm_token', '');
    if(payload.code) data.append('two_fa_code', payload.code);

    return AUTH_CLIENT.post('/oauth/token', data);
  }

  public static getMyAccount(): Promise<AxiosResponse> {
    return AUTH_CLIENT.get('api/account/getMyAccount');
  }

  public static login(payload: { email: string, password: string }): Promise<AxiosResponse> {
    return AUTH_CLIENT.post('auth/login', payload);
  }

  public static initiatePasswordReset(email: string): Promise<AxiosResponse> {
    return AUTH_CLIENT.post('/api/passwordReset/request', {
      email: email
     });
  }

  public static resetPassword(token: string, password: string): Promise<AxiosResponse> {
    return AUTH_CLIENT.post('/api/passwordReset/setPassword', {
      token: token,
      newPassword: password
    });
  }

  /**
   * Register a new Restaurateur
   * @param email Email for the new Account
   * @param password Password for the Account
   * @param companyName Company Name for the Restaurant
   */
  public static registerRestaurateur(email: string, password: string, companyName: string): Promise<AxiosResponse> {
    return AUTH_CLIENT.post('api/restaurateur/register', {
      email,
      password,
      company: {
        name: companyName,
        legalName: companyName,
      }
    });
  }

  public static verifyEmailWithToken(token: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post('api/account/confirm-email-change', {
      token: token
    });
  }
}
