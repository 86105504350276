import { AxiosResponse } from 'axios';
import { AUTH_CLIENT } from '../misc/AuthClient';

/**
 * repository that deals with all the api requests that have to do with users
 */
export default class ValidationRepository {
    private static baseUrl: string = 'api/validation';

    /**
     * gets the user based on the token that is used
     */
    public static getValidationLength(): Promise<AxiosResponse> {
        return AUTH_CLIENT.get(`${this.baseUrl}/lengths`);
    }
}
