import Parseable from '@/misc/Parseable';
import Contact from "@/models/Contact";
import Address from "@/models/Address";
import {CorporateForm} from "@/enum/CorporateForm.enum";
import OnBoardingProgress from "@/models/OnBoardingProgress";

export default class Company extends Parseable {
    public id!: string;
    public name!: string;
    public legalName!: string;
    public contact!: Contact;
    public address!: Address;
    public iban!: string;
    public deliveryAddress?: Address | null;
    public registrationNumber!: string;
    public taxExempt!: boolean;
    public corporateForm!: CorporateForm;
    public visible!: boolean;
    public onboardingCompleted!: boolean;
    public onboardingProgress!: OnBoardingProgress;

    public static parseFromObject(object: any): Company {
        const company = new Company();
        Object.assign(company, object);
        company.contact = Contact.parseFromObject({...company.contact});
        company.address = Address.parseFromObject(company.address);
        return company;
    }

    public parseToObject(): Partial<Company> {
        return { ...this };
    }
}
