import Parseable from '@/misc/Parseable';
import {LocationStatus} from "@/enum/LocationStatus.enum";

export default class Address extends Parseable {
    public id!: string;
    public city!: string;
    public country!: string;
    public street!: string;
    public locationStatus!: LocationStatus;
    public zip!: string;
    public visible!: boolean;

    public static parseFromObject(object: any): Address {
        const company = new Address();
        Object.assign(company, object);
        return company;
    }

    public parseToObject(): Partial<Address> {
        return { ...this };
    }

    public get fullAddress() {
        return `${this.street}, \n\n ${this.zip} ${this.city}`;
    }

    /**
     * checks if the address is complete by checking if every parameter is set,
     * since the parameters are strings, we can't just use check (e.g. address.street && address.zip ...)
     * so we check if every parameter is not set and invert the result (cause this works with strings)
     * @param address
     */
    public static isAddressComplete(address?: Address): boolean {
        return  !(!address?.street || !address?.zip || !address?.city || !address?.country);
    }
}
