import AuthRepository from '@/api/repositories/AuthRepository';
import {AuthState, RootState, ValidationsState} from '@/interfaces/StoreStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import AccountRepository from "@/api/repositories/AccountRepository";
import Account from '@/models/Account';
import {AccountType} from "@/enum/AccountType.enum";
import RestauranteurRepository from "@/api/repositories/RestauranteurRepository";
import Restauranteur from "@/models/Restaurateur";
import PlatformOperatorRepository from "@/api/repositories/PlatformOperatorRepository";
import PlatformOperator from "@/models/PlatformOperator";
import Validations from "@/models/Validations.model";
import ValidationRepository from "@/api/repositories/ValiationRepository";
import ContainerEvent from "@/models/ContainerEvent.model";
import {ContainerStoreMutations} from "@/store/container.store";

export const VALIDATION_STORE_NAME = 'validation';

export const enum ValidationStoreActions {
    FETCH_VALIDATIONS = 'FETCH_VALIDATIONS',
}

export const enum ValidationStoreMutations {
    SET_VALIDATIONS = 'SET_VALIDATIONS',
    CLEAR_STORE = 'CLEAR_STORE',
}

export const enum ValidationStoreGetters {
    GET_VALIDATIONS = 'GET_VALIDATIONS',
}

function initialValidationState(): ValidationsState {
    return {
        validations: undefined
    };
}

const store: ValidationsState = initialValidationState();

const actions: ActionTree<ValidationsState, RootState> = {
    [ValidationStoreActions.FETCH_VALIDATIONS]: async ({ commit }): Promise<void> => {
        try {
            const response = await ValidationRepository.getValidationLength();
            Validations.parseFromObject(response.data);
        } catch(_) {
            Validations.setFallbackValues();
        }
    },
};

const mutations: MutationTree<ValidationsState> = {
    [ValidationStoreMutations.SET_VALIDATIONS]: (state: ValidationsState, value: Validations) => {
       state.validations = value;
    },
    [ValidationStoreMutations.CLEAR_STORE]: (state: ValidationsState) => {
        // Merge rather than replace so we don't lose observers
        // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
        Object.assign(state, initialValidationState());
    }
};

const getters: GetterTree<ValidationsState, RootState> = {
    [ValidationStoreGetters.GET_VALIDATIONS]: (state: ValidationsState) => state.validations,
};

const validationStore: Module<ValidationsState, RootState> = {
    state: store,
    actions: actions,
    mutations: mutations,
    getters: getters,
};

export default validationStore;
