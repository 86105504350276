import {ContainerTypeState, FeedState, RootState} from '@/interfaces/StoreStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import ContainerType from "@/models/ContainerType.model";
import ContainerTypeRepository from "@/api/repositories/ContainerTypeRepository";
import {FeedStoreGetters, FeedStoreMutations} from "@/store/feed.store";

export const CONTAINER_TYPE_STORE_NAME = 'containerTypes';

export const enum ContainerTypeStoreActions {
    GET_ALL = 'GET_ALL',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

export const enum ContainerTypeStoreMutations {
    CLEAR_STORE = 'CLEAR_STORE',
    SET_CONTAINER_TYPES = 'SET_CONTAINER_TYPES'
}

export const enum ContainerTypeStoreGetters {
    GET_CONTAINER_TYPES = 'GET_CONTAINER_TYPES'
}

function initialContainerTypeState(): ContainerTypeState {
    return {containerTypes: undefined};
}

const store: ContainerTypeState = initialContainerTypeState();

const actions: ActionTree<ContainerTypeState, RootState> = {
    [ContainerTypeStoreActions.GET_ALL]: async ({commit}, companyId: string | undefined): Promise<ContainerType[]> => {
        // Try to Load all Containers
        // if it fails return an empty list
        try {
            const containers = await ContainerTypeRepository.getAll(companyId);
            await commit(ContainerTypeStoreMutations.SET_CONTAINER_TYPES, containers.data);
            return containers.data;
        } catch (_) {
            return [];
        }
    },
    [ContainerTypeStoreActions.CREATE]: async ({commit}, payload: {
        companyId: string,
        individualRequest: boolean,
        lastPenaltyRate: number,
        type: ContainerType
    }): Promise<ContainerType | undefined> => {
        try {
            const newType = await ContainerTypeRepository.createContainerType({
                companyId: payload.companyId,
                individualRequest: payload.individualRequest,
                lastPenaltyRate: payload.lastPenaltyRate,
                containerType: payload.type
            });
            return newType.data;
        } catch (_) {
            return undefined;
        }
    },
    [ContainerTypeStoreActions.UPDATE]: async ({commit}, payload: {
        companyId: string,
        containerTypeId: string,
        lastPenaltyRate: number,
        containerType: Partial<ContainerType>
    }): Promise<ContainerType | undefined> => {
        try {
            // Gather data from Payload
            const { containerTypeId, containerType, lastPenaltyRate, companyId } = payload;

            // Update Container with new Data
            const updatedType = await ContainerTypeRepository.updateContainerType({
                companyId,
                containerTypeId,
                lastPenaltyRate,
                containerType,
            });

            // return Updated Type
            return updatedType.data;
        } catch (_) {
            return undefined;
        }
    },
    [ContainerTypeStoreActions.DELETE]: async ({commit}, containerTypeId: string): Promise<boolean> => {
        try {
            // Delete Container
            await ContainerTypeRepository.deleteContainerType(containerTypeId);
            return true;
        } catch (_) {
            return false;
        }
    },
};

const mutations: MutationTree<ContainerTypeState> = {
    [ContainerTypeStoreMutations.CLEAR_STORE]: (state: ContainerTypeState) => {
        // Merge rather than replace so we don't lose observers
        // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
        Object.assign(state, initialContainerTypeState());
    },
    [ContainerTypeStoreMutations.SET_CONTAINER_TYPES]: (state: ContainerTypeState, value: ContainerType[]) => {
        state.containerTypes = value;
    }
};

const getters: GetterTree<ContainerTypeState, RootState> = {
    [ContainerTypeStoreGetters.GET_CONTAINER_TYPES]: (state: ContainerTypeState) => state.containerTypes
};

const containerTypeStore: Module<ContainerTypeState, RootState> = {
    state: store,
    actions: actions,
    mutations: mutations,
    getters: getters,
};

export default containerTypeStore;
