import { AxiosResponse } from 'axios';
import { AUTH_CLIENT } from '../misc/AuthClient';
import News from "@/models/News.model";
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * repository that deals with all the api requests that have to do with news
 */
export default class NewsRepository {
    private static baseUrl: string = 'api/news';

    /**
     * gets a company object by the id
     * @param companyId
     */
    public static getNews(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/company/${companyId}`);
    }

    /**
     * gets a news based on the passed id
     * @param newsId
     */
    public static getNewsById(newsId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/${newsId}`);
    }

    /**
     * creates a new news based on the passed payload
     * @param payload
     */
    public static createNews(payload: News): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`${this.baseUrl}`, payload);
    }

    /**
     * updates a news passed on the passed news id and the passed news
     * @param newsId
     * @param payload
     */
    public static updateNews(payload: News): Promise<AxiosResponse> {
        return ENTITY_CLIENT.put(`${this.baseUrl}/${payload.id}`, payload);
    }

    /**
     * deletes a news by the id
     * @param newsId
     */
    public static deleteNews(newsId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.delete(`${this.baseUrl}/${newsId}`);
    }

    public static getBackgrounds(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/backgrounds`);
    }
}
