import {CompanyState, RootState} from '@/interfaces/StoreStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import Company from "@/models/Company";
import CompanyRepository from "@/api/repositories/CompanyRepository";
import Employee from "@/models/Employee";

export const COMPANY_STORE_NAME = 'companies';

export const enum CompanyStoreActions {
    GET_ALL = 'GET_ALL',
    GET_EMPLOYEES = 'GET_EMPLOYEES'
}

export const enum CompanyStoreMutations {
    SET_COMPANIES = 'SET_COMPANIES',
    SET_EMPLOYEES = 'SET_EMPLOYEES',
    CLEAR_STORE = 'CLEAR_STORE'
}

export const enum CompanyStoreGetters {
    COMPANIES = 'COMPANIES',
    EMPLOYEES = 'EMPLOYEES'
}

function initialCompanyState(): CompanyState {
    return {
        employees: []
    };
}

const store: CompanyState = initialCompanyState();

const actions: ActionTree<CompanyState, RootState> = {
    [CompanyStoreActions.GET_ALL]: async ({ commit }): Promise<Company[]> => {
        // fetches the news of the company
        const response = await CompanyRepository.getCompanies();

        // maps the api response as news objects and stores them in the feed store
        const companies = response.data.map((n: any) => Company.parseFromObject(n));
        commit(CompanyStoreMutations.SET_COMPANIES, companies);

        // returns the fetched news
        return companies;
    },
    [CompanyStoreActions.GET_EMPLOYEES]: async ({ commit }, companyId: string): Promise<Employee[]> => {
        // fetches the news of the employees
        const response = await CompanyRepository.getEmployeesOfCompany(companyId);

        // parses the response as employees and stores them in the store
        const employees = response.data.map((n: any) => Employee.parseFromObject(n));
        commit(CompanyStoreMutations.SET_EMPLOYEES, employees);

        // returns the fetched employees
        return employees;
    }
};

const mutations: MutationTree<CompanyState> = {
    [CompanyStoreMutations.SET_COMPANIES]: (state: CompanyState, value: Company[]) => {
        state.companies = value;
    },
    [CompanyStoreMutations.SET_EMPLOYEES]: (state: CompanyState, value: Employee[]) => {
        state.employees = value;
    },
    [CompanyStoreMutations.CLEAR_STORE]: (state: CompanyState) => {
        // Merge rather than replace so we don't lose observers
        // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
        Object.assign(state, initialCompanyState());
    }
};

const getters: GetterTree<CompanyState, RootState> = {
    [CompanyStoreGetters.COMPANIES]: (state: CompanyState) => state.companies,
    [CompanyStoreGetters.EMPLOYEES]: (state: CompanyState) => state.employees
};

const companyStore: Module<CompanyState, RootState> = {
    state: store,
    actions: actions,
    mutations: mutations,
    getters: getters,
};

export default companyStore;
