import Parseable from '@/misc/Parseable';

/**
 * contact information of an entity
 */
export default class Contact extends Parseable {
    public id!: string;
    public email!: string;
    public phone!: string;
    public firstName!: string;
    public lastName!: string;
    public position!: string;
    public title!: string;


    public static parseFromObject(object: any): Contact {
        const contact = new Contact();
        Object.assign(contact, object);
        return contact;
    }

    public parseToObject(): Partial<Contact> {
        return { ...this };
    }
}

