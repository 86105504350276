import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import CustomNotifications from '@/plugins/custom-notifications/CustomNotifications.plugin';
import '@/styles/main.scss';
import ErrorHandlerPlugin from './plugins/ErrorHandler.plugin';
import FormattingPlugin from '@/plugins/Formatting.plugin';
import AuthorizationPlugin from './plugins/Authorization.plugin';

import Toast from 'vue-toastification';
import "vue-toastification/dist/index.css";

/*
  globally including script for google tag manager
*/
import "./scripts/gtm.js";

Vue.use(Toast, {});

// @ts-ignore
import VueMask from 'v-mask';

// @ts-ignore
import VueAppleLogin from 'vue-apple-login';
import {DateTime} from "luxon";

//@ts-ignore
import {StripePlugin} from "@vue-stripe/vue-stripe";

//@ts-ignore
import vBraintree from 'vue-braintree';
Vue.use(vBraintree);

/**
 * settings for apple sso
 */
const appleAuthOptions = {
  clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
  scope: 'name email',
  redirectURI: process.env.VUE_APP_APPLE_REDIRECT_URI,
  usePopup: true,
  state: DateTime.now().toString()
};

Vue.config.productionTip = false;

const stripeOptions = {
  testMode: process.env.VUE_APP_STRIPE_TEST_MODE!,
  pk: process.env.VUE_APP_STRIPE_PK,
  locale: 'de-De',
};
Vue.use(StripePlugin, stripeOptions);

// Register custom plugins:
Vue.use(CustomNotifications);
Vue.use(ErrorHandlerPlugin);
Vue.use(FormattingPlugin);
Vue.use(AuthorizationPlugin);
Vue.use(VueMask);
Vue.use(VueAppleLogin, appleAuthOptions);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
