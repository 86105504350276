import { AxiosResponse } from 'axios';
import { AUTH_CLIENT } from '../misc/AuthClient';

/**
 * repository that deals with all the api requests that have to do with users
 */
export default class SSORepository {
    private static baseUrl: string = 'api/sso';

    /**
     * tries to login with apple sso
     */
    public static loginWithApple(idToken: string): Promise<AxiosResponse> {
        return AUTH_CLIENT.post(`${this.baseUrl}/apple?clientId=WEB`, {
            "idToken": idToken,
            "web": true,
            "fcmToken": ""
        });
    }

    /**
     * tries to login with google sso
     * @param idToken
     */
    public static loginWithGoogle(idToken: string): Promise<AxiosResponse> {
        return AUTH_CLIENT.post(`${this.baseUrl}/google?clientId=WEB`, {
            "idToken": idToken,
            "fcmToken": ""
        });
    }
}
