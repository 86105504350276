import Parseable from '@/misc/Parseable';
import {AccountType} from "@/enum/AccountType.enum";

export default class PlatformOperator extends Parseable {
    public id?: string;
    public accountId?: string;
    public role?: AccountType;

    public static parseFromObject(object: any): PlatformOperator {
        const platformOperator = new PlatformOperator();
        Object.assign(platformOperator, object);
        return platformOperator;
    }

    public parseToObject(): Partial<PlatformOperator> {
        return { ...this };
    }
}
